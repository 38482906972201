import { WithKeyboardChange } from '@platform/base';
import { ViewModel } from '@platform/api';
import { getVisibleStatusInStates, itemIsDisable, itemIsVisible, processCodeClassName } from './utils';

const { keyByModuleId } = WithKeyboardChange.createKeyMap;

const matchItemFocus = {
    refer: setReferItemFocus,
    input: setNormalItemFocus,
    textarea: setTextAreaFocus,
    select: setSelectItemFocus,
    // radio checkbox 类型统一改为了下拉
    radio: setSelectItemFocus,
    checkbox: setSelectItemFocus,
    switch: setSwitchItemFocus,
    switch_browse: setSwitchItemFocus,
    normal: setNormalItemFocus,
};
let firstItemNum = 0;

// 不可用单元组件的判断
const unusableUnit = function (item, states, moduleId) {
    // 这个方法是使用Meta数据来进行跳转的  可能会有隐患  因为组件内部数据是 自己的状态 visiable 和disabled 可能会改变
    let disabledItem = states.disabled || {},
        visibleItem = states.visible || {},
        allForm = states.form,
        attrCode = item.attrcode;

    if (item.itemtype === 'label') return true;
    return itemIsDisable(disabledItem, item) ||
        !allForm[attrCode] ||
        itemIsVisible(visibleItem, item, moduleId);
};
// 聚焦到表单后的下个元素
const focusToFormNext = function (areaList, moduleId) {
    let currentIndex = areaList.indexOf(moduleId);
    if (currentIndex === -1 || currentIndex === areaList.length - 1) { // 没有 或者最后一个 退出
        return null;
    }

    // keyByModuleId  这一段逻辑或许也要循环  TODO
    let nextIndex = (currentIndex === areaList.length - 1) ? 0 : currentIndex + 1,
        nextAreaId = areaList[nextIndex];
    let activeTable = keyByModuleId[nextAreaId];
    if (!activeTable || !activeTable['activeTable']) { // 不存在 table
        return focusToFormNext(areaList, nextAreaId);
    }
    let childTabId = activeTable['activeTable']; // 活跃的子表格
    // 要聚焦的子表格
    let targetTable = keyByModuleId[childTabId];
    // 卡片表格
    if (activeTable['type'] && activeTable['type'] === 'cardTable' && childTabId) {
        // 由于表格之类的 还有多页签情况  所以对多页签处理
        let activeTabDom = document.querySelector(` #js_cardTable_${nextAreaId} #js_lightTabs_header_${nextAreaId} .active a`) ||
            document.querySelector(` #js_cardTable_${nextAreaId}  .active a`);
        // TODO  这里用id来查找  一旦发送变动  很可能找不到
        if (activeTabDom) {
            activeTabDom.focus();
            return { type: 'table', id: nextAreaId };
        } else {
            // 递归查找
            return focusToFormNext(areaList, nextAreaId);
        }
    }
    // 普通表格 或者 编辑表格  跳转到具体的单元格
    let targetTableUnitId = targetTable['firstItem'];
    let firstItemDom = document.querySelector(`div[${targetTableUnitId}] div[tabindex="0"]`);
    if (firstItemDom) {
        ViewModel.shouldAutoFocus = true;
        firstItemDom.ncExecuteFocus = true;
        firstItemDom.focus();
        delete firstItemDom.ncExecuteFocus;
        // 直接执行  ie下会有顺序问题
        setTimeout(() => {
            ViewModel.shouldAutoFocus = false;
        }, 100);
        return { type: 'table', id: nextAreaId };
    } else {
        // 递归查找
        return focusToFormNext(areaList, nextAreaId);
    }
};

// 获取meta中 moudule首项
const getMetaModuleFirstItem = function (meta, moduleId, states) {
    return meta[moduleId].items.find((ite, index) => {
        if (!unusableUnit(ite, states, moduleId)) {
            return ite;
        }
        return false;
    });
};

// 聚焦到表单下一项
const focusNextItem = function (item, formId) {
    let attrCode = processCodeClassName(item.attrcode);
    matchItemFocus[item.itemtype] === undefined ? matchItemFocus['normal'](formId, attrCode) : matchItemFocus[item.itemtype](formId, attrCode);
};

// 聚焦到页面第一个表单位置
const focusToFirstForm = function (formId, meta, states) {
    let target = getMetaModuleFirstItem(meta, formId, states);
    target && focusNextItem(target, formId);
    return target;
};

function setReferItemFocus(formId, unitCode) {
    let $form = document.getElementById(`form_${formId}`);
    let dom = $form && $form.querySelector(`.${unitCode}.form-item`);
    let inputEl = dom && dom.querySelector("input[tabindex='0']");
    let spanEl = dom && dom.querySelector("span[tabindex='0']");
    let wrapper = dom && dom.querySelector(".template-item-wrapper[tabindex='0']");
    if (wrapper) {
        //元素存在就让它聚焦
        wrapper.fromProgram = true;
        wrapper.focus();
        dom = null;
    } else if (inputEl) {
        //元素存在就让它聚焦
        inputEl.fromProgram = true;
        inputEl.focus();
        dom = null;
    } else if (spanEl) {
        spanEl.fromProgram = true;
        spanEl.focus();
        dom = null;
    } else {
        dom && dom.addEventListener(
            'DOMSubtreeModified',
            //autoFocusInTricky.bind(null, dom)
            function wrap(e) {
                autoFocusInTricky(dom, wrap, e);
            },
        );
    }
    // dom = null;
    inputEl = null;
    spanEl = null;
}

/**
 * todo: 由于refer组件是异步加载，input获取焦点时无法得到dom元素，因此在这儿监听dom更改事件, 希望在refer内部触发一个告诉外部，组件一渲染完成
 */

function autoFocusInTricky(dom, fn, e) {
    let inputEl = e.target.querySelector("input[tabindex='0']");
    // inputEl = null
    if (inputEl) {
        setTimeout(() => {
            inputEl.fromProgram = true;
            inputEl.focus();
            dom && dom.removeEventListener('DOMSubtreeModified', fn);
            inputEl = null;
            dom = null;
        });
    }
}

function setNormalItemFocus(formId, unitCode) {
    let $form = document.getElementById(`form_${formId}`);
    // let inputEl = $form && $form.querySelector(`.${unitCode}.form-item input`);
    let inputEl = $form && $form.querySelector(`.${unitCode}.form-item .template-item-wrapper[tabindex='0']`);

    if (inputEl) {
        inputEl.fromProgram = true;
        inputEl.focus();
    }
    inputEl = null;
}

function setTextAreaFocus(formId, unitCode) {
    let $form = document.getElementById(`form_${formId}`);
    let textEl = $form && $form.querySelector(`.${unitCode}.form-item .template-item-wrapper[tabindex='0']`);

    if (textEl) {
        textEl.fromProgram = true;
        textEl.focus();
    }
    textEl = null;
}

function setSelectItemFocus(formId, unitCode) {
    let $form = document.getElementById(`form_${formId}`);
    let dom = $form && $form.querySelector(`.${unitCode}.form-item`);
    let focusDom = dom && dom.querySelector(".template-item-wrapper[tabindex='0']") || dom.querySelector('input');

    if (focusDom) {
        focusDom.fromProgram = true;
        focusDom.focus();
    }

    dom = null;
    focusDom = null;
}

function setSwitchItemFocus(formId, unitCode) {
    let $form = document.getElementById(`form_${formId}`);
    let switchEl = $form && $form.querySelector(`.${unitCode}.form-item .template-item-wrapper[tabindex='0']`);

    if (switchEl) {
        switchEl.fromProgram = true;
        switchEl.focus();
    }
    switchEl = null;
}

// 获取表单的下个可聚焦元素
const getFormNextFocuser = function ({ states, meta, moduleId, attrcode }) {
    let currentNext = null,
        tagIndex = 99999,
        fitems = meta[moduleId].items,
        fitemsLen = fitems.length,
        disabledState = (states || {}).disabled || {},
        visibleState = (states || {}).visible || {};

    fitems.find((ite, index) => {
        if (fitemsLen === index + 1) {
            return false;
        }
        if (ite.attrcode == attrcode || index === tagIndex) {
            currentNext = fitems[index + 1];
            // 这里要处理下 如果后面设置了状态 meta中的数据就无效了
            if (
                unusableUnit(currentNext, states, moduleId) ||
                currentNext.itemtype === 'label' ||
                (currentNext.visible === false && getVisibleStatusInStates({ visibleState, moduleId, attrCode: currentNext.attrcode })) ||
                (currentNext.disabled === true && disabledState[currentNext.attrcode] !== false)
            ) {
                tagIndex = index + 1;
                currentNext = null;
            } else {
                return true;
            }
        }
        return false;
    });
    return currentNext;
};

// 获取下个可聚焦元素
const getNextFocuser = function ({ states, meta, moduleId, attrcode, head, formrelation = [], orderOfHotKey = [], opened, getStore }) {
    if (!states || !meta) { // 超级例外情况
        return {
            type: 'other',
            id: moduleId,
        };
    }
    let formNextItem = null;
    // icon 的情况
    if (!attrcode && opened !== undefined) {
        let nextIindex = moduleId === head ? 0 : (formrelation.indexOf(moduleId) + 1),
            nextFormId = formrelation[nextIindex]; // 下个表的位置

        // 处理下区域隐藏的情况
        let nextAreaVisible = getStore([moduleId, 'areaVisible']);
        nextAreaVisible = nextAreaVisible === undefined ? true : nextAreaVisible;
        if (!nextAreaVisible) {
            // 处理下区域隐藏的情况
            let isunfold = getStore([nextFormId, 'isunfold']);
            isunfold = isunfold === undefined ? true : isunfold;
            // 有可能整个 区域都没有可编辑元素  那么就直接跳转到下个分组的icon上  或者nextFormId没有直接跳转到表格  又或者跳转到表格
            // 相当于没有展开 opened false
            return getNextFocuser({ states, meta, moduleId: nextFormId, attrcode, head, formrelation, orderOfHotKey, opened: isunfold, getStore });
        }

        if (opened === true) { // 展开 聚焦到当前表单第一元素
            formNextItem = getMetaModuleFirstItem(meta, moduleId, states);
            // 有首项元素  跳转到首项
            if (formNextItem) {
                return {
                    type: 'form',
                    item: formNextItem,
                    last: true,
                    id: moduleId,
                };
            } else {
                // 有可能整个 区域都没有可编辑元素  那么就直接跳转到下个分组的icon上  或者nextFormId没有直接跳转到表格  又或者跳转到表格
                // 相当于没有展开 opened false
                return getNextFocuser({ states, meta, moduleId, attrcode, head, formrelation, orderOfHotKey, opened: false });
            }
        }

        // 当前未展开 跳到下个分组
        if (nextFormId && opened !== true) {
            return {
                type: 'icon', // icon table other
                id: nextFormId,
            };
        }
        // 最后一个表单 未展开
        if (!nextFormId && opened !== true) {
            let formSibling = focusToFormNext(orderOfHotKey, head);
            formNextItem = getMetaModuleFirstItem(meta, head, states);
            // 如果表格不是编辑态   循环？？？
            if (!formSibling && formNextItem) {
                return {
                    type: 'form',
                    last: true,
                    item: formNextItem,
                    id: head,
                };
            }
            return formSibling || getNextFocuser({});
        }
    }
    // 获取formNextItem  当前表单内部跳转
    formNextItem = getFormNextFocuser({ states, meta, moduleId, attrcode });
    if (formNextItem) {
        return {
            type: 'form',
            item: formNextItem,
            id: moduleId,
        };
    }
    // 定义
    const flength = formrelation.length,
        noTableArea = orderOfHotKey.length === 0 || (orderOfHotKey.length === 1 && orderOfHotKey[0] === head),
        noFormChild = flength === 0;

    // 表单循环 可能会引起独立表单的  循环
    if ((head === moduleId && noFormChild && noTableArea) || (formrelation[flength - 1] === moduleId && noTableArea)) {
        console.log('只有单表 没有分组和表格 或者 没有表格 当前为最后一个表单分组， 跳转到首个表单！！');
        formNextItem = getMetaModuleFirstItem(meta, head, states);
        if (formNextItem) {
            return {
                type: 'form',
                last: true,
                item: formNextItem,
                id: head,
            };
        } else {
            // 连当前表单都禁用了  没辙了  不 可以考虑dom查找
            return {} || getNextFocuser({});
        }
    }
    //  有表格区
    if ((noFormChild && !noTableArea) || (formrelation[flength - 1] === moduleId && !noTableArea)) {
        console.log('当前主表单  并且没有子表单分组  有表格区 或者 最后一个子表单 有表格区域, 跳转到表格区');
        let formSibling = focusToFormNext(orderOfHotKey, head);
        formNextItem = getMetaModuleFirstItem(meta, head, states);
        // 如果表格不是编辑态   循环？？？
        if (!formSibling && formNextItem) {
            return {
                type: 'form',
                last: true,
                item: formNextItem,
                id: head,
            };
        }
        return formSibling || getNextFocuser({});
    }
    // 当前为表单区域  并且不是最后一个子表单
    if (flength > 0 && formrelation.indexOf(moduleId) !== flength - 1) {
        let nextIindex = 0,
            nextFormId = null,
            currentModuleId = moduleId;
        // 直接跳到  下个元素的展开按钮上
        nextIindex = currentModuleId === head ? 0 : (formrelation.indexOf(currentModuleId) + 1);
        nextFormId = formrelation[nextIindex]; // 下个表的位置

        // 处理下区域隐藏的情况
        let nextAreaVisible = getStore([nextFormId, 'areaVisible']);
        nextAreaVisible = nextAreaVisible === undefined ? true : nextAreaVisible;
        if (!nextAreaVisible) {
            // 处理下区域隐藏的情况
            let isunfold = getStore([nextFormId, 'isunfold']);
            isunfold = isunfold === undefined ? true : isunfold;
            // 有可能整个 区域都没有可编辑元素  那么就直接跳转到下个分组的icon上  或者nextFormId没有直接跳转到表格  又或者跳转到表格
            // 相当于没有展开 opened false
            return getNextFocuser({ states, meta, moduleId: nextFormId, attrcode, head, formrelation, orderOfHotKey, opened: isunfold, getStore });
        }

        if (nextFormId) { //
            return {
                type: 'icon', // icon table other
                id: nextFormId,
            };
        } else {
            return {} || getNextFocuser({});
        }
    }

    return null;
};

const tabindexSearch = function (currentDom) {
    if (!currentDom) {
        return false;
    }
    let tabindexDom = currentDom.querySelector('input,a,textarea,[tabindex]:not([tabindex="-1"]');
    if (!tabindexDom) {
        return tabindexSearch(tabindexDom.nextElementSibling || tabindexDom.parentElement);
    } else {
        // 这里其实还有很多判断  我偷懒就先不写了
        // 包括 tabindex = -1, diabled readonly visible hidden等
        tabindexDom.focus();
    }
};

const focusToNext = function ({ states, meta, moduleId, attrcode, head, formrelation = [], orderOfHotKey = [], opened, itemType, onLastFormEnter, getStore }) {
    let nextFocuser = getNextFocuser({ states, meta, moduleId, attrcode, head, formrelation, orderOfHotKey, opened, getStore });
    // console.log(nextFocuser);
    if (!nextFocuser) {
        return null;
    }

    if (nextFocuser.last === true && onLastFormEnter) {
        console.log('form last', nextFocuser);
        // focusNextItem(nextFocuser.item, nextFocuser.id);
        // if (typeof onLastFormEnter == 'function' && onLastFormEnter() === false) {
        //     return;
        // }
        return typeof onLastFormEnter == 'function' && onLastFormEnter() === false;
    }

    if (nextFocuser.type === 'form') {
        console.log('form', nextFocuser);

        // 关掉错误日期的弹窗  当前日期格式弹窗错误时 可以直接跳下个但是没关
        if (document.querySelector('.rc-calendar-input-invalid')) {
            let $form = document.getElementById(`form_${moduleId}`);
            // let inputEl = $form && $form.querySelector(`.${unitCode}.form-item input`);
            let inputEl = $form && $form.querySelector(`.${attrcode}.form-item .template-item-wrapper[tabindex] input`);

            inputEl && inputEl.click();
        }
        if (firstItemNum === 8) {
            document.activeElement.blur();
            console.log(firstItemNum);
            setTimeout(() => firstItemNum = 0, 500);
            return;
        }
        focusNextItem(nextFocuser.item, nextFocuser.id);
        preventInfiniteLoop(nextFocuser);
    }
    if (nextFocuser.type === 'icon') {
        console.log('icon', nextFocuser);
        let toggleIcon = document.querySelector(`#toggleIcon2${nextFocuser.id}`);
        // console.log(toggleIcon);
        toggleIcon && toggleIcon.focus();
    }
    if (nextFocuser.type === 'other') {
        // DOM查找
        tabindexSearch(document.activeElement);
    }
    return nextFocuser;
};

function preventInfiniteLoop(nextFocuser) {
    if (keyByModuleId[nextFocuser.id] && keyByModuleId[nextFocuser.id].firstItem === nextFocuser.item.attrcode) {
        firstItemNum++;
    }
}

export {
    focusToFormNext,
    focusToFirstForm,
    getMetaModuleFirstItem,
    unusableUnit,
    focusNextItem,
    focusToNext,
};
