import { BaseStore, hookFactory } from '@platform/template';
import { PubSub } from '@platform/api';
const FORMCOMPLETE = 'areaChangeComplete';

function merge(target, ...rest) {
    return target;
}

function setInitialProperty (target, prop, value) {
    !target && (target = {});
    if (!target.hasOwnProperty(prop)) {
        target[prop] = value;
    }
    return value;
}

class FormStore extends BaseStore {
    // 初始化 Store 有序Store 根据表单
    store = {};

    // 属性唯一标志 避免与表单ID冲突
    items = '_FORM_ITEMS_';

    // 布局
    layout = Symbol('form layout');

    //状态
    status = Symbol('form status');

    getHooks = () => ({
        getItemValue: this.getItemValue,
        getAllFormValue: this.getAllFormValue,
        setItemValue: this.setItemValue,
        setAllFormValue: this.setAllFormValue,
        setCtx: this.setCtx,
        reset: this.reset,
        setWrapper: this.setWrapper,
        getStatus: this.getStatus,
        setStatus: this.setStatus,
        closeArea: this.closeArea,
        openArea: this.openArea,
        emptyAllFormValue: this.emptyAllFormValue,
        setMeta: this.setMeta,
        cancel: this.cancel,
        countFormWidth: this.countFormWidth,
        setItemAttribute: this.setItemAttribute,
        getItemAttribute: this.getItemAttribute,
        isCheckNow: this.isCheckNow,
        replaceItem: this.replaceItem,
        getMetaItems: this.getMetaItems,
        getOldValue: this.getOldValue,
        setOldValue: this.setOldValue,
        forceUpdate: this.forceUpdate,
        setAreaVisible: this.setAreaVisible,
        getAreaVisible: this.getAreaVisible,
        // 内部使用方法
        getInnerHooks: this.getInnerHooks,
    });

    // =========== 内部使用方法 ===========
    getInnerHooks = () => {
        return {
            setInitialValues: this.setInitialValues,
            getItem: this.getItem,
            getStore: this.getStore,
            setStore: this.setStore,
            getSingleForm: this.getSingleForm,
            getFormItem: this.getFormItem,
            getAllFormItem: this.getAllFormItem,
            getFormRelations: this.getFormRelations,
            getFormList: this.getFormList,
            getLayout: this.getLayout,
            setLayout: this.setLayout,
            getFormTabs: this.getFormTabs,
            setFormTabs: this.setFormTabs,
            countFormWidth: this.countFormWidth,
            setJson: this.setJson,
            getJson: this.getJson,
            getReplaceItem: this.getReplaceItem,
            initMeta: this.initMeta,
        };
    };

    getStore = path => {
        return this.get(path);
    };

    setStore = (path, value, shouldUpdate = true) => {
        let newValue = this.set(path, value);
        shouldUpdate && this.forceUpdate(() => {
            PubSub.publish(FORMCOMPLETE, true);
        })
        return newValue
    };

    // 初始化meta数据 mergeStore  转化meta
    setInitialValues = (meta, formId) => {
        // await Promise.resolve(() => {});
        if (!meta || Object.keys(meta).length === 0) return;

        const { formrelation, formassociatedrelation, pageid } = meta;
        let isTabsForm = false;
        let arr = [formId],
            tabsId = [formId],
            formRelation = {},
            formitems = {},
            store = {};

        // 存在多页签表单时  进行页签数据合并
        if (formassociatedrelation && formassociatedrelation.hasOwnProperty(formId) && Array.isArray(formassociatedrelation[formId].formtabs)) {
            tabsId = formassociatedrelation[formId].formtabs;
            arr = tabsId;
            isTabsForm = true;
        }
        // 存在子表时 进行子表数据合并
        if (formrelation) {
            if (isTabsForm) {
                let result = [];
                // 遍历主表，找子表
                arr.map(item => {
                    result.push(item);
                    if (Array.isArray(formrelation[item])) {
                        result = result.concat(formrelation[item]);
                        formRelation[item] = formrelation[item]
                    }
                })
                arr = result
            } else {
                if (Array.isArray(formrelation[formId])) {
                    arr = arr.concat(formrelation[formId])
                }
            }
        }

        arr.forEach(item => {
            // 判断 moduletype 为 form 再进行数据转换
            if (meta[item].moduletype === 'form') {
                let { items, status, isunfold, areaVisible, ...others } = meta[item];

                // 初始化 status
                if (formId === item) {
                    // 已经用过API设置 status 不再取meta上status状态
                    if (!store[this.status]) {
                        store[this.status] = this.getStore([this.status]) || status || 'browse';
                    }
                }

                store[item] = {
                    ...others,
                    items: [],
                    // isunfold、areaVisible也可以通过api设值
                    isunfold: this.getStore([item, '__dirty__'])?.hasOwnProperty('isunfold') ? this.getStore([item, 'isunfold']) : isunfold,
                    areaVisible: this.getStore([item, '__dirty__'])?.hasOwnProperty('areaVisible') ? this.getStore([item, 'areaVisible']) : areaVisible,
                    __dirty__: this.getStore([item, '__dirty__']),
                };

                let itemsFromStore = this.getStore([this.items]);

                items.forEach(formItem => {
                    formItem['belongTo'] = item;
                    // 如果已经进行过出初始化 从新setMeta 不会初始化value 其他属性根据meta生成
                    // 兼容情况 已经存在fieldvalue 说明已经在上次setmeta时进行了fieldvalue初始化、为了兼容不在进行初始化、正常情况应该业务组每次setmeta都根据meta生成初始值fieldvalue
                    if (!this.getStore([this.items, formItem.attrcode, 'fieldValue'])) {
                        //设置默认值
                        let initialvalue = null;
                        if (store[this.status] === 'add') {
                            initialvalue = formItem.initialvalue || null;
                        }
                        if (formItem.itemtype === 'checkbox_switch' || formItem.itemtype === 'switch') {
                            initialvalue = initialvalue || {
                                display: (this.getStore(['json']) || {})['page-form-0004'],
                                value: false,
                            };
                        } else if (formItem.itemtype === 'switch_browse') {
                            initialvalue = initialvalue || { value: false };
                        } else {
                            initialvalue = initialvalue || {
                                display: null,
                                value: null,
                            };
                        }
                        formItem['fieldValue'] = initialvalue;
                        formItem['formOldValues'] = initialvalue.value ? { value: initialvalue.value } : { value: null };
                        formItem['formBack'] = initialvalue;
                        //多语字段初始化
                        if (formItem.itemtype === 'residtxt' && Array.isArray(formItem.languageMeta)) {
                            formItem.languageMeta.map(i => {
                                let index = i.index == '1' ? '' : i.index;
                                return formItem.attrcode + index;
                            }).forEach(i => {
                                if (i !== formItem.attrcode) {
                                    formitems[i] = {
                                        fieldValue: {
                                            display: '',
                                            value: '',
                                        },
                                    }
                                }
                            });
                        }
                    } else {
                        //字段存在 不需要重新初始fieldvalue 
                        formItem['fieldValue'] = this.getStore([this.items])[formItem.attrcode] && this.getStore([this.items])[formItem.attrcode]['fieldValue']
                    }
                    
                    // disabled visible required verify要通过api设值。
                    let { disabled, visible, required, verify, ...propsToPass } = formItem;
                    formitems[formItem.attrcode] = { __origin__: {}, ...itemsFromStore?.[formItem.attrcode], ...propsToPass };
                    ['disabled', 'visible', 'required', 'verify'].forEach(attribute => {
                        if (this.getStore([this.items, formItem.attrcode, '__dirty__', attribute])) {
                            // 取store
                            formitems[formItem.attrcode][attribute] = itemsFromStore?.[formItem.attrcode]?.[attribute];
                        } else {
                            // 取meta
                            formitems[formItem.attrcode][attribute] = formItem[attribute];
                        }
                        formitems[formItem.attrcode]['__origin__'][attribute] = formItem[attribute];
                    })

                    // 校验字段
                    formItem['verify'] = true;
                    store[item].items.push(formItem.attrcode);
                });
            }
        });

        // 保存上次容器存值 假如存在就替换
        store[this.items] = { ...(this.getStore([this.items]) || {}), ...formitems };

        store[this.layout] = this.getStore([this.layout]) || {
            width: 50,
            column: 2,
        };

        // 所有表ID
        store.formList = arr;
        // 页签表ID
        store.tabsId = tabsId;
        // 主表ID
        store.mainId = formId;
        // 主子表关系
        store.formRelation = formRelation;
        // 页签选中
        store.activeTab = tabsId[0];
        // 是否多页签表单
        store.isTabsForm = isTabsForm;

        // pageid
        store.pageid = pageid

        this.store = Object.assign(this.store, store);
        this.hasRender = true;
        console.log(`Form ${formId}'s store: `, this.store);
    };

    // 获取当前编辑性
    getStatus = () => {
        return this.get([this.status]);
    };

    // 设置当前编辑性
    setStatus = value => {
        let status = this.setStore([this.status], value);
        return status
    };

    // 获取布局
    getLayout = () => {
        return this.get([this.layout]);
    };

    // 从新计算布局
    setLayout = value => {
        let layout = this.setStore([this.layout], value);
        return layout
    };

    // 获取一组表单
    getSingleForm = id => {
        return this.get([id, 'items']);
    };

    // 获取单个数据
    getFormItem = name => {
        return this.get([this.items, name]);
    };

    // 获取所有数据
    getAllFormItem = () => {
        return this.get([this.items]);
    };

    getFormRelations = () => {
        return this.get('formRelation');
    }

    getFormList = () => {
        return this.get('formList');
    }

    //  设置activeTab
    setFormTabs = id => {
        let activeTab = this.setStore(['activeTab'], id);
        return activeTab
    }

    // 获取activeTab
    getFormTabs = () => {
        return this.get(['activeTab']);
    }
    // Form 宽度自适应
    countFormWidth = (e, width) => {
        if (!width) {
            let name = this.get(['mainId'])
            let ele = document.getElementById(name);
            if (ele) {
                width = ele.offsetWidth;
            }
        }

        //优化效率：默认是4列，宽度在4列范围内不再渲染
        let formItemInfo = { width: 50, column: 2 };
        if (width) {
            let formItemWidth = 50;
            let column = 2;
            if (width < 600) {
                formItemWidth = 100;
                column = 1;
            }
            if (width >= 600 && width < 900) {
                formItemWidth = 50;
                column = 2;
            }
            if (width >= 900 && width < 1230) {
                formItemWidth = 33.333333;
                column = 3;
            }
            if (width >= 1230 && width < 1820) {
                formItemWidth = 25;
                column = 4;
            }
            if (width >= 1820) {
                formItemWidth = 20;
                column = 5;
            }
            formItemInfo.width = formItemWidth;
            formItemInfo.column = column;
            this.setLayout(formItemInfo);
        }
    };
    // 设置表单多语
    setJson = json => {
        this.setStore(['json'], json)
    }
    // 获取多语
    getJson = () => {
        return this.get(['json']) || {}
    }

    // =========== 业务组使用api ===========
    // 取store
    getItemValue = (name, type) => {
        let value;
        // 兼容处理多语字段
        if (type) {
            let languageMeta = this.get([this.items, name, 'languageMeta']);
            // 多语字段 兼容提供对象
            if (languageMeta) {
                value = {};
                languageMeta.forEach(item => {
                    let index = item.index === '1' ? '' : item.index;
                    value[name + index] = this.get([this.items, name + index, 'fieldValue']) || { display: null, value: null };
                })
            } else {
                value = this.get([this.items, name, 'fieldValue']) || { display: null, value: null };
            }
        } else {
            value = this.get([this.items, name, 'fieldValue']) || { display: null, value: null };
        }

        return value
    };

    // 设置Store item value
    setItemValue = (name, value, { cancel, inner = false } = {}) => {
        // const prevStore = this.store;
        // this.prevStore = prevStore;
        let oldValue = this.getStore([this.items, name, 'fieldValue']);
        let newStore = this.setStore([this.items, name, 'fieldValue'], value, false);

        if (!inner) {
            this.setOldValue(name, oldValue);
            if (!cancel) {
                this.setStore([this.items, name, 'formBack'], value, false);
            }
        }
        this.updateItem([name]);
        return newStore;
    };

    getOldValue = name => {
        return this.getStore([this.items, name, 'formOldValues']);
    }

    setOldValue = (name, oldValue) => {
        this.setStore([this.items, name, 'formOldValues'], oldValue, false);
    }

    // 设置属性
    setItemAttribute = (name, attribute, value, shouldUpdate = true) => {
        if (attribute && typeof attribute === 'object') {
            // 批量设置
            let values = attribute;
            attribute = name;
            for (let key of Object.keys(values)) {
                this.setItemAttribute(key, attribute, values[key], false)
            }
        } else {
            // 设置单个字段
            if (this.get([this.items, name])) {
                this.setStore([this.items, name, attribute], value, false);
                this.setStore([this.items, name, '__dirty__', attribute], true, false);
            } else {
                console.warn('form' + name + '不存在')
            }
        }
        shouldUpdate && this.forceUpdate();
    };

    // 获取属性
    getItemAttribute = (name, attribute) => {
        return this.get([this.items, name, attribute]);
    };

    // 设置全部值
    setAllFormValue = (data, copyFlag, emptyOldVal, setOldValueExceptKey, callback) => {
        for (let [attrcode, value] of Object.entries(data)) {
            // 编辑关联项用setOldValueExceptKey，这个字段不设旧值
            this.setItemValue(attrcode, value, { cancel: false, inner: attrcode === setOldValueExceptKey })
            // const prevStore = this.store;
            // this.prevStore = prevStore;
            // this.setStore([this.items, attrcode, 'fieldValue'], value, false);
        }
        this.forceUpdate(callback)
    };

    // 获取全部值
    getAllFormValue = () => {
        let res = {};
        for (let [attrcode, obj] of Object.entries(
            this.get([this.items]) || {},
        )) {
            if (attrcode) {
                res[attrcode] = obj['fieldValue'] || {
                    display: null,
                    value: null,
                };
            }
        }
        return res;
    };
    // 展开表单某个区域
    openArea = (name, shouldUpdate = true) => {
        let newValue = this.setStore([name, 'isunfold'], true, shouldUpdate);
        this.setStore([name, '__dirty__', 'isunfold'], true, false);
        return newValue
    }
    // 收起表单某个区域
    closeArea = (name, shouldUpdate = true) => {
        let newValue = this.setStore([name, 'isunfold'], false, shouldUpdate);
        this.setStore([name, '__dirty__', 'isunfold'], false, false);
        return newValue
    }

    setAreaVisible = (moduleId, visible) => {
        this.setStore([moduleId, 'areaVisible'], visible, true);
        this.setStore([moduleId, '__dirty__', 'areaVisible'], true, false);
    }

    getAreaVisible = moduleId => {
        return this.getStore([moduleId, 'areaVisible']) !== false;
    }

    // 校验表单
    isCheckNow = () => {
        let firstErrorModuleId = '',
            firstErrorTab = '',
            tabsId = this.getStore('tabsId'),
            formRelation = this.getStore('formRelation'),
            itemsMap = this.getStore([this.items]),
            requiredItems = [], 
            regItems = [], 
            maxItems = [],
            switchfalse = ['switch', 'switch_browse', 'radio', 'checkbox_switch'],
            lengthTypes = ['input', 'number', 'textarea'],
            isCheckNowItems = [],
            errorGroup = new Set(),
            hasError = false;

        // 变量items 校验 不根据meta校验 meta中字段存在问题
        (this.getStore(['formList']) || []).forEach(formId => {
            if (this.getAreaVisible(formId)) {
                isCheckNowItems = isCheckNowItems.concat(this.getStore([formId])['items'] || [])
            }
        })

        isCheckNowItems.forEach(item => {
            let currentItem = itemsMap[item];
            let { label, required, visible, belongTo, reg, fieldValue, itemtype, maxlength } = currentItem;
            if (visible) {
                let value = fieldValue?.value;
                let error = false;
                if (required && [null, undefined, ''].includes(value) && !switchfalse.includes(itemtype)) {
                    error = true;
                    requiredItems.push(label)
                }
                if (value && reg && !reg.test(value)) {
                    error = true;
                    regItems.push(label);
                }

                if (maxlength && lengthTypes.includes(itemtype) && value && String(value).length > maxlength) {
                    error = true;    
                    maxItems.push(label);
                }

                this.setStore([this.items, item, 'verify'], !error, false);

                if (error) {
                    // 展开有错误的分组，并定位到第一个含有错误字段的页签
                    if (!firstErrorModuleId) {
                        firstErrorModuleId = belongTo;
                    }
                    errorGroup.add(belongTo)
                }
            }
        })
        
        // 找到第一个含有错误字段的页签
        if (tabsId.includes(firstErrorModuleId)) {
            firstErrorTab = firstErrorModuleId;
        } else {
            firstErrorTab = tabsId.find(tabId => {
                return Array.isArray(formRelation[tabId]) && formRelation[tabId].includes(firstErrorModuleId);
            })
        }

        [...errorGroup].forEach(moduleId => {
            hasError = true;
            this.openArea(moduleId, false);
        })

        firstErrorTab && this.setStore('activeTab', firstErrorTab, false)

        this.forceUpdate();
        return { requiredItems, regItems, maxItems, hasError };
    }

    // 取消操作
    cancel = () => {
        let items = this.get([this.items]) || {};
        for (let key of Object.keys(items)) {
            this.setStore([this.items, key, 'fieldValue'], this.get([this.items, key, 'formBack']), false)
            this.setStore([this.items, key, 'verify'], true, false)
        }
        this.setStore([this.status], 'browse', false);
        this.forceUpdate()
    }

    // 清空数据
    emptyAllFormValue = (exceptArr = [], cancel = true) => {
        let switchFalse = ['switch', 'switch_browse', 'checkbox_switch'];
        let itemsMap = this.get([this.items]);
        itemsMap && Object.keys(itemsMap).forEach(item => {
            let initialvalue = this.getStore([this.items, item, 'initialvalue'])
            if (!exceptArr.includes(item)) {
                if (switchFalse.includes(itemsMap[item].itemtype)) {
                    this.setStore([this.items, item, 'fieldValue'], initialvalue ? initialvalue : {
                        value: false,
                        display: '否',
                    }, false);
                } else {
                    // if(itemsMap[item].itemtype == 'residtxt'){
                    //     this.setStore([this.items, item, 'fieldValue'], {
                    //         value: {},
                    //         display: null,
                    //     }, false);
                    // }else {

                    this.setStore([this.items, item, 'fieldValue'], initialvalue ? initialvalue : {
                        value: null,
                        display: null,
                    }, false);
                    // }
                }
                if (cancel) {
                    this.setStore([this.items, item, 'formBack'], {
                        value: null,
                        display: null,
                    }, false)
                }
            }
        })
        this.forceUpdate()
        // console.log(this.store, this)
    }

    // 表单重置操作
    reset = () => {
        this.store = {};
        // this.wrapper && this.wrapper.refresh();
    };

    // 获取当前区域 谨慎操作
    getCtx = () => { };

    // 设置当前区域
    setCtx = ctx => {
        this.ctx = ctx;
    };

    // 重置meta 
    /**
     * @description: setmeta 是否需要ID store初始化应该具有ID的 createform 时记录
     * @param {type} 
     * @return: 
     */
    setMeta = (meta, id, shouldUpdate = true) => {
        console.log(`Form ${id}'s meta: `, meta)
        // this.setStore(['meta'], meta, false)
        let currentID = id || this.getStore('mainId')
        this.setInitialValues(meta, currentID)
        shouldUpdate && this.forceUpdate();
    }

    /**
     * @description: 替换组件
     * @param {type} 
     * @return: 
     */
    replaceItem = (attrcode, itemDom) => {
        this.setStore(['replaceItem', attrcode], itemDom)
    }

    getReplaceItem = attrcode => {
        return this.getStore(['replaceItem', attrcode])
    }
    // 外层wrapper 暂时 重置 reset时使用 可以把reset 抽到每一个formitem上面
    setWrapper = ctx => {
        this.wrapper = ctx;
    };

    // 获取items // 支持主子表ID; 兼容容错
    getMetaItems = id => {
        let items = (this.getStore([id]) && this.getStore([id])['items']) || [];
        let res = items.map(item => {
            let { __dirty__, __origin__, ...itemFromStore } = this.getStore([this.items, item]);
            let itemFromOrigin = ['disabled', 'visible', 'required', 'verify'].reduce((o, attribute) => {
                o[attribute] = this.getStore([this.items, item, '__origin__', attribute]);
                return o;
            }, {})
            return { ...itemFromStore, ...itemFromOrigin };
        })
        return res
    }
    // store meta数据销毁 

    initMeta = () => {
        // 暂时这样处理
        this.store = {};
        this.forceUpdate()
    }
}

export const useForm = hookFactory(FormStore);

