import React, { Component } from 'react';

function wrapperComponent(C) {
    class WrapperComponent extends Component {
        constructor(props) {
            super(props);
            this.state = { resetCount: 0 };
            if(this.props.store === null){
                // 暂时通过error 提醒业务组、 稳定后可去掉
                throw Error(`正在创建formID为${this.props.name}的表单, 还未通过use.form注册,请先注册此ID`)
            }else {
                this.props.store.setWrapper(this);
            }
            
        }

        refresh = () => {
            this.setState(({ resetCount }) => ({ resetCount: resetCount + 1 }));
        };

        render() {
            const { resetCount } = this.state;
            return <C {...this.props} key={resetCount} />;
        }
    }
    return WrapperComponent;
}

export default wrapperComponent;
