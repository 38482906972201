/*
 * @Descripttion: 
 * @version: 
 * @Author: bbq
 * @Date: 2020-08-23 16:13:06
 * @LastEditors: bbq
 * @LastEditTime: 2020-09-01 20:31:12
 */

/**
 *  enterkey使用
 * @desc 现在attrCode 名称中有 xxx.xx 的情况导致 查询dom 元素时 找不到的情况
 * @resolve 如果attrCode中存在.  xxx.xx -> xxx_xx
 * @param name
 * @return name
 */
export function processCodeClassName(name) {
    return name.indexOf('.') === -1
        ? name
        : name.replace(/\./g, '_');
}

//在form组件的state中获取元素是否可见 enterkey使用
export function getVisibleStatusInStates({ visibleState, moduleId, attrCode }) {
    if (visibleState[moduleId]) {
        return visibleState[moduleId][attrCode] === false;
    } else {
        return visibleState[attrCode] === false;
    }
}

//  enterkey使用
export function itemIsDisable(disableState, item) {
    let { attrcode } = item;

    if (disableState[attrcode] !== true) return false;

    return disableState[attrcode] === true || item.disabled === true;
}

//  enterkey使用
export function itemIsVisible(visibleState, item, moduleId) {
    let { attrcode } = item;
    return getVisibleStatusInStates({ visibleState, moduleId, attrCode: attrcode }) || item.visible === false;
}

/**
 * 给编辑前使用
 * @param fields {array} 所有字段
 * @param disableFields {object} 不可聚焦啊字段
 * @param currentItem {object} 当前字段
 * @return {object}
 */
export function findFocusItem(fields, disableFields, currentItem) {
    let next = '',
        prev = '';

    fields.forEach((item, index) => {
        if (item == currentItem.attrcode) {
            let nextField = fields[index + 1];
            let prevField = fields[index - 1];
            next = disableFields[nextField] === true
                ? findNextFocusItem(fields.slice(index + 1), disableFields)
                : nextField;

            prev = disableFields[prevField] === true
                ? findPrevFocusItem(fields.slice(0, index - 1), disableFields)
                : prevField;
        }
    });

    return {
        next,
        prev,
    };
}

function findNextFocusItem(fields, disableFields) {
    return fields.find(item => {
        return disableFields[item] !== true;
    });
}

function findPrevFocusItem(fields, disableFields) {
    return fields.reverse().find(item => {
        return disableFields[item] !== true;
    });
}