import React from 'react';
import classnames from 'classnames';
import { useForm } from './store';
import wrapperComponent from './wrapper';
import SingleForm from './singleForm';
import FromContext from './formContent';
import { getSafeRandom } from '@platform/api'
import { throttle, getMultiLang, getSysFieldid, PubSub } from '@platform/api';
import { ItemProvider, BaseComponent } from '@platform/template';
import { Tooltip, Tabs, HotKeys, ScrollElement } from '@platform/base';
import { WithKeyboardChange } from '@platform/base';
import { focusToNext } from './enterkey';

const { FormWithKeyboardChange } = WithKeyboardChange;


require('./form.less');

const { TabPane } = Tabs;
const keyMap = {
    nextTabHandler: ['shift+right'],
    prevTabHandler: ['shift+left'],
};
// form变化,表格重新计算标识
const BEFOREFORMCOMPLETE = 'beforeAreaChangeComplete';
const FORMCOMPLETE = 'areaChangeComplete';

function uuidv4() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (getSafeRandom() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

@ItemProvider
@wrapperComponent
@FormWithKeyboardChange
class Form extends BaseComponent {
    constructor(props) {
        super(props);
        const { store, config, name } = props;
        // 实例化时 已知表单查询区ID 缓存ID和config
        if (name && store) {
            const { setStore } = store.getInnerHooks();
            setStore(['mainId'], name, false);
            setStore(['FormInstance'], this, false)
        }
        this.prevFormHeight = 0
        // this.renderFlag = false;
        this.state = { prevWidth: '' }
        this.uniqueKey = uuidv4();
        window[this.uniqueKey] = store.getInstance();
    }

    // 判断初始化meta
    UNSAFE_componentWillMount() {
        const { store } = this.props;
        const { setJson } = store.getInnerHooks();
        // 初始化多语加载 
        getMultiLang({
            moduleId: "page_form",
            callback: setJson,
        })
    }

    // //还原之前逻辑
    // componentDidMount() {
    //     const { store, name } = this.props;
    //     const { countFormWidth } = store.getInnerHooks()
    //     let form = document.getElementById(`form_${name}`);
    //     let formWidth;
    //     if (form) {
    //         formWidth = form.offsetWidth;
    //     }
    //     if (formWidth) {
    //         countFormWidth()
    //         window.addEventListener('resize', throttle(countFormWidth, 17));
    //         this.setState({ prevWidth: formWidth }, () => {
    //             // 触发cardtable高度重新计算
    //             PubSub.publish(FORMCOMPLETE, true);
    //         });
    //     }

    // }

    // componentDidUpdate(nextProps, nextState) {
    //     const { store, formComponentUpdate } = this.props;
    //     const { countFormWidth, getStore } = store.getInnerHooks()
    //     // 宽度发生变化时，再重新计算
    //     // if (!this.renderFlag) {
    //     let form = document.getElementById(`form_${nextProps.name}`);
    //     if (form) { // 优化效率：先判断Form是否存在，再计算form.offsetWidth
    //         let formWidth = form.offsetWidth;
    //         let formHeight = form.offsetHeight;

    //         if (formWidth && (formWidth != nextState.prevWidth || formHeight != this.prevFormHeight)) {
    //             countFormWidth('', formWidth);
    //             this.prevFormHeight = formHeight
    //             this.setState({ prevWidth: formWidth }, () => {
    //                 // 触发cardtable高度重新计算
    //                 PubSub.publish(FORMCOMPLETE, true);
    //                 // this.renderFlag = true;
    //             });
    //             window.addEventListener('resize', countFormWidth);
    //         }
    //         // 内存释放
    //         // form = null
    //     }
    //     // }

    //     if(typeof formComponentUpdate === 'function'){
    //         formComponentUpdate({ ...this.state, ...getStore() });
    //     } 

    //     // 快捷键逻辑
    //     nextProps.formStatusChanged(nextProps, store.getStatus());
    // }

    componentDidUpdate(nextProps, nextState) {
        const { store } = this.props;
        // 快捷键逻辑
        nextProps.formStatusChanged(nextProps, store.getStatus());
    }

    // componentWillUnmount() {
    //     const { store } = this.props;
    //     const { countFormWidth } = store.getInnerHooks();
    //     window.removeEventListener('resize', countFormWidth);

    //     // 组件销毁时 是否需要重置store 这个需要考虑处理下 
    //     // initMeta()
    // }

    componentWillUnmount() {
        delete window[this.uniqueKey];
    }

    // 快捷键跳到下一个元素逻辑处理
    fastToNext = (store, moduleId, attrcode, opened, itemType) => {
        let { getHotKeyConfig = () => { } } = this.props;
        let { meta, orderOfHotKey, onLastFormEnter } = getHotKeyConfig() || {};
        let { getStore, getAllFormItem } = store.getInnerHooks();
        let mainFormId = getStore('mainId');
        // 處理states 數據 TODO 後面可以考慮優化下
        let items = getAllFormItem() || {};
        let states = {
            disabled: {},
            visible: {},
            form: {},
        };

        for (let key in items) {
            let item = items[key];
            states.disabled[item.attrcode] = !!item.disabled;
            states.visible[item.attrcode] = !!item.visible;
            states.form[item.attrcode] = item;
        }

        // console.log(mainFormId, states, moduleId, attrcode, meta, orderOfHotKey, onLastFormEnter, form);
        let params = {
            states,
            meta,
            moduleId,
            attrcode: attrcode,
            head: mainFormId || moduleId,
            formrelation: meta && meta.formrelation ? meta.formrelation[mainFormId || moduleId] : [mainFormId || moduleId],
            orderOfHotKey: orderOfHotKey,
            onLastFormEnter: onLastFormEnter,
            opened: opened,
            itemType,
            getStore,
        };
        focusToNext(params);
    }

    // 区域展开收起
    toggleArea = (name, value) => {
        const { openArea, closeArea } = this.props.store;
        PubSub.publishSync(BEFOREFORMCOMPLETE, true);
        if (value) {
            closeArea(name);
        } else {
            openArea(name)
        }
        setTimeout(() => {
            PubSub.publish(FORMCOMPLETE, true);
        }, 0)
    };

    // 渲染函数
    renderForm = tabsId => {
        const { store, name, config, getHotKeyConfig } = this.props;
        const { getStore, getJson, getSingleForm, getFormItem } = store.getInnerHooks();
        let isTabsForm = getStore(['isTabsForm']);
        let langJson = getJson();
        let list = [];
        let mainId = tabsId || name;
        if (tabsId) {
            let groupIds = getStore(['formRelation'])[tabsId];
            if (Array.isArray(groupIds)) {
                list = [tabsId].concat(groupIds)
            } else {
                list = [tabsId]
            }
        } else {
            // ids
            list = getStore(['formList']) || [];
        }
        return (
            <div className="group-form-wrapper">
                {/* moduleId */}
                {list.map(item => {
                    // items
                    let list = getSingleForm(item) || [];
                    // 过滤 单表是否所有字段都不显示
                    list = list.filter(e => (getFormItem(e) || {}).visible == true);
                    let areaVisible = getStore([item, 'areaVisible']);
                    areaVisible = areaVisible === undefined ? true : areaVisible;
                    if (areaVisible && list.length !== 0) {
                        let isunfold = getStore([item, 'isunfold']);
                        // 兼容页签主表 isunfold false情况
                        if ((getStore(['tabsId']) || []).includes(item)) {
                            isunfold = true
                        } else {
                            isunfold = isunfold === undefined ? true : isunfold;
                        }

                        let groupFormClass = classnames({
                            'show-form': isunfold,
                            'hide-form': !isunfold,
                        });
                        let rightClassName = classnames({ rightForm: item == name && config && config.headLeftArea && typeof config.headLeftArea === 'function' });
                        let Wrapper = isTabsForm && mainId === item ? React.Fragment : ScrollElement
                        return (
                            <React.Fragment>
                                <Wrapper name={item} >
                                    <div
                                        className="group-form-wrapper cf"
                                        // 给快捷键用 如果要改 注意下
                                        id={`form_${item}`}
                                    >
                                        {item == name && config && config.headLeftArea &&
                                            typeof config.headLeftArea === 'function' &&
                                            (
                                                <div className="left-defined-area">{config.headLeftArea()}</div>
                                            )}
                                        {mainId !== item && (
                                            <div className="group-form-name">
                                                <div
                                                    className="operator"
                                                    fieldid={getSysFieldid(`${mainId && item}_group`)}
                                                    tabIndex={0}
                                                    id={`toggleIcon2${item}`}
                                                    onClick={this.toggleArea.bind(
                                                        this,
                                                        item,
                                                        isunfold,
                                                    )}
                                                    onKeyDown={e => {
                                                        if (e.keyCode === 32) { // space
                                                            e.preventDefault();
                                                            e.target.click();
                                                        }
                                                        if (e.keyCode === 13) { // enter
                                                            e.preventDefault();
                                                            this.fastToNext(store, item, null, isunfold);
                                                        }
                                                    }}
                                                >
                                                    <Tooltip
                                                        placement="top"
                                                        overlay={isunfold ? langJson['form-group-close'] : langJson['form-group-expand']}
                                                        delayHide={1}
                                                        delayShow={1000}
                                                    >
                                                        <span
                                                            className="name"
                                                        >
                                                            <span className="name-icon">
                                                                {isunfold ? '-' : '+'}
                                                            </span>
                                                            {getStore([item, 'name'])}
                                                        </span>
                                                    </Tooltip>
                                                    <span
                                                        className="line-wrap"
                                                    >
                                                        <span className="line" />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {isunfold && <div className={`group-form-item ${groupFormClass} ${rightClassName}`}>
                                            {item == name && config && config.headTopArea &&
                                                typeof config.headTopArea === 'function' &&
                                                (
                                                    <div className="top-defined-area">{config.headTopArea()}</div>
                                                )}
                                            <SingleForm
                                                form={store}
                                                name={item}
                                                className={groupFormClass}
                                                list={list}
                                                // 快捷键逻辑
                                                fastToNext={(attrcode, itemType) => {
                                                    this.fastToNext(store, item, attrcode, undefined, itemType);
                                                }}
                                                // 快捷键
                                                getHotKeyConfig={getHotKeyConfig}
                                            />
                                        </div>}
                                    </div>
                                </Wrapper>
                            </React.Fragment>
                        );
                    }
                })}
            </div>
        );
    };

    // 多页签处理
    renderTabForm = () => {
        const { store } = this.props;
        const { getStore, getFormTabs, setFormTabs, getSingleForm, getFormItem, getFormRelations } = store.getInnerHooks();
        let tabsList = getStore(['tabsId']),
            currentKey = getFormTabs(),
            formRelations = getFormRelations(),
            status = store.getStatus();

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={{
                    // 切换下一个页签
                    nextTabHandler: () => {
                        let index = tabsList.indexOf(currentKey);
                        let nextIndex = index + 1;
                        if (nextIndex > tabsList.length - 1) {
                            nextIndex = 0
                        }
                        setFormTabs(tabsList[nextIndex])
                    },
                    //  切换上一个页签
                    prevTabHandler: () => {
                        let index = tabsList.indexOf(currentKey);
                        let prevIndex = index - 1;
                        if (prevIndex < 0) {
                            prevIndex = tabsList.length - 1
                        }
                        setFormTabs(tabsList[prevIndex])
                    },
                }}
            >
                {tabsList.reduce((child, tabName) => {
                    return <ScrollElement name={tabName} id={tabName}>
                        {child}
                    </ScrollElement>
                }, <Tabs
                    activeKey={currentKey}
                    onChange={i => setFormTabs(i)}
                >
                    {tabsList.map(item => {
                        let groups = formRelations[item] || [];
                        let hasEditableField = false, hasErrorField = false;
                        status !== 'browse' && [item, ...groups].some(moduleId => {
                            return getSingleForm(moduleId).some(attrcode => {
                                let itemInfo = getFormItem(attrcode);
                                if (itemInfo.visible && !itemInfo.disabled) {
                                    hasEditableField = true;
                                }
                                if (itemInfo.verify === false) {
                                    hasErrorField = true;
                                }
                                return hasEditableField && hasErrorField;
                            })
                        });
                        return <TabPane
                            key={item}
                            tab={<div>
                                {hasEditableField && <i className="iconfont icon-yebj"></i>}
                                {getStore([item])['name']}
                                {hasErrorField && <i className="iconfont icon-djgantanhao" ></i>}
                            </div>}
                        >
                            {this.renderForm(item)}
                        </TabPane>
                    })}
                </Tabs>)}
            </HotKeys>
        )
    }

    //
    renderDom = () => {
        const { store } = this.props
        const { getStore } = store.getInnerHooks();
        if (getStore(['isTabsForm'])) {
            return this.renderTabForm()
        } else {
            return this.renderForm()
        }
    }

    render() {
        const { store, config, name } = this.props;
        const { onAfterEvent, onBeforeEvent } = config;
        return (
            <FromContext.Provider value={{ areaId: name, form: store, onAfterEvent, onBeforeEvent, config }}>
                <div
                    id={`form_${name}`}
                    fieldid={getSysFieldid(`${(config && config.fieldid) || name}_form-area`)}
                    className="platform-component-form"
                    uniqueKey={this.uniqueKey}
                >
                    {this.renderDom()}
                </div>
            </FromContext.Provider>

        );
    }
}

Form.displayName = 'Form';
Form.useForm = useForm;

export default Form;
