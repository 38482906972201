/*
 * @Descripttion: 
 * @version: 
 * @Author: wanglongx
 * @Date: 2020-08-24 13:53:35
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-02-20 16:58:52
 */
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Filed from './field';
import { PubSub } from '@platform/api';
import { WithAutoFocus } from '@platform/base';
const { FormWithAutoFocus } = WithAutoFocus;

// form变化,表格重新计算标识
const BEFOREFORMCOMPLETE = 'beforeAreaChangeComplete';
const FORMCOMPLETE = 'areaChangeComplete';

function debounce(fn, wait = 300) {
    let timer = null;
    return function (...rest) {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => {
            fn.apply(this, rest);
        }, wait);
    }
}

// 创建单个form区域
@FormWithAutoFocus
class SingleForm extends Component {
    constructor(props) {
        super(props);
        this.state = { layout: { width: 50, column: 2 } };
    }

    componentDidMount() {
        this.calcWidth();
        window.addEventListener('resize', debounce(this.calcWidth));
    }

    componentDidUpdate() {
        this.calcWidth();
    }

    calcWidth = () => {
        let { prevWidth } = this.state;
        let width = findDOMNode(this)?.offsetWidth;
        // 列数需要根据组件宽度得到，而以下数值是UE给的分辨率
        // 组件宽度 + 32 = 分辨率
        // 加30缓冲区（如：滚动条）
        width = width + 32 + 30;
        if (prevWidth !== width) {
            PubSub.publishSync(BEFOREFORMCOMPLETE, true);
            //优化效率：默认是4列，宽度在4列范围内不再渲染
            let layout = { width: 50, column: 2 };

            if (width) {
                let formItemWidth = 50;
                let column = 2;
                if (width < 600) {
                    formItemWidth = 100;
                    column = 1;
                }
                if (width >= 600 && width <= 1024) {
                    formItemWidth = 50;
                    column = 2;
                }
                if (width >= 1025 && width <= 1439) {
                    formItemWidth = 33.333333;
                    column = 3;
                }
                if (width >= 1440 && width <= 1919) {
                    formItemWidth = 25;
                    column = 4;
                }
                if (width >= 1920) {
                    formItemWidth = 20;
                    column = 5;
                }
                layout.width = formItemWidth;
                layout.column = column;
                this.setState({ layout });
            }
            this.setState({ prevWidth: width }, () => {
                PubSub.publish(FORMCOMPLETE, true);
            })
        }
    }

    renderSingle = () => {
        let { form, list = [], name, fastToNext, getHotKeyConfig } = this.props;
        const { getFormItem, getLayout } = form.getInnerHooks();
        const { layout } = this.state;
        // 宽度计算
        let colTotalNum = 0;
        let leftSpaceCol = 0;
        let rightSpaceCol = 0;
        list = list.filter(item => getFormItem(item)['visible'] == true)
        return list.map((itemCode, index) => {
            let item = getFormItem(itemCode);
            let column = layout.column;
            let currentColNum = 1;
            if (item.colnum && Number(item.colnum) > 1) {
                if (item.colnum < column) {
                    currentColNum = Number(item.colnum);
                } else {
                    currentColNum = column;
                }
            }
            if (index === 0) {
                rightSpaceCol = column - currentColNum;
                colTotalNum = currentColNum;
            } else {
                leftSpaceCol = rightSpaceCol; //上个字段的右空白,用于折行控制
                colTotalNum = colTotalNum + currentColNum;
                if (currentColNum > rightSpaceCol || item.isnextrow) {
                    //自动换行
                    colTotalNum = colTotalNum + rightSpaceCol;
                    rightSpaceCol = column - currentColNum;
                } else {
                    //继续放在当前行
                    let remain = colTotalNum % column;
                    rightSpaceCol = 0;
                    if (remain != 0) {
                        rightSpaceCol = column - remain;
                    }
                }
            }
            let width = currentColNum * layout.width;
            return (
                <Filed
                    {...item}
                    key={item.attrcode}
                    width={width}
                    leftSpaceCol={leftSpaceCol}
                    singleWidth={layout.width}
                    singleFormCode={name}
                    fastToNext={fastToNext}
                // 通過formContent 传递
                // getHotKeyConfig={getHotKeyConfig}
                />
            );
        });
    };

    render() {
        const { className } = this.props;
        return (
            <div
                className={`group-form-item lightapp-component-form ${className}`}
            >
                {this.renderSingle()}
            </div>
        );
    }
}

export default SingleForm;
