/*
 * @Descripttion: 
 * @version: 
 * @Author: wanglongx
 * @Date: 2020-06-05 09:35:24
 * @LastEditors: bbq
 * @LastEditTime: 2021-02-20 10:24:20
 */
import React, { useContext } from 'react';
import { Item } from '@platform/template';
import FromContext from './formContent';
import { Tooltip } from '@platform/base';
import { processCodeClassName } from './utils';
import {
    formatDot,
    addZero,
    commafy,
    getRandomPassword,
    changeTime,
    getLangCode,
    linkTo,
    ajax,
    toast,
    getSysFieldid,
} from '@platform/api';
import { findFocusItem } from './utils';
// IM refer refpath
const IMpath = [
    'uap/refer/riart/userDefaultRefer/index',
    'uapbd/refer/psninfo/PsndocRiartTreeGridRef/index',
    'uap/refer/riart/userAllTableRef/index',
    'uapbd/refer/psninfo/PsndocTreeGridRef/index',
];
function Filed(props) {
    let {
        label,
        attrcode,
        width,
        leftSpaceCol,
        isnextrow,
        singleWidth,
        required,
        itemtype,
        verify,
        errorMsg,
        errormessage,
        languageMeta,
        singleFormCode,
        hyperlinkflag,
        renderStatus,
        refcode,
        color,
        render,
        fastToNext,
        canBeEditedWhenBrowse,
        maxlength,
    } = props;

    const { areaId, form, onAfterEvent, onBeforeEvent, config = {} } = useContext(FromContext);
    const { getItemValue, setItemValue, getStatus, getInnerHooks, getAllFormValue, setOldValue } = form;
    const { getJson, getReplaceItem, getStore } = getInnerHooks();
    const status = getStatus();
    const json = getJson();
    let realBrowse = !['switch_browse', 'attachment'].includes(itemtype) && (status === 'browse' && !canBeEditedWhenBrowse);
    let leftWidth = 0;
    if (isnextrow) {
        // 折行
        leftWidth = Number(leftSpaceCol) * singleWidth;
    }
    let colon = '';
    if (label && realBrowse) {
        colon = ':';
    }
    if (verify === false) {
        if (realBrowse) {
            errorMsg = null;
        } else {
            let checkModalVerify = [...document.querySelectorAll('.refer-pop-window ')].filter(
                e => e.style.display === 'flex',
            );
            if (checkModalVerify.length == 0) { //弹出参照时不校验
                errorMsg = (
                    <span
                        style={{
                            color: 'red',
                            fontSize: '10px',
                        }}
                        className="input-error-message"
                    >
                        {errormessage || json['page-form-0008']}
                    </span>
                );
            }
        }
    }
    // 替换组件 
    props.renderItem = function ({ isEdit }) {
        if (isEdit) {
            let customRender;
            // 表格用
            if (renderStatus === 'edit' && typeof render === 'function') {
                customRender = render(getItemValue(attrcode), getAllFormValue());
            } else {
                customRender = getReplaceItem(attrcode)
            }
            if (customRender) {
                return customRender;
            }
        }
    }
    // switch_browse 特殊处理
    if (itemtype === 'switch_browse') {
        let config = getStore(['FormInstance', 'props', 'config']);
        props.cancelPSwitch = config.cancelPSwitch;
        props.disabled = config.editSwitch ? props.disabled : (status === 'browse' ? props.disabled : true)
    }

    if (itemtype === 'attachment') {
        props.disableModify = status === 'browse' ? props.disabled : true;
    }

    let content = (
        <div className="form-item-errormsg">
            {errorMsg}
        </div>
    );
    // refer IM
    let IMreferFlag = false;
    if (itemtype === 'refer' && (getItemValue(attrcode) || {}).value && window.top.ImTool) {
        if (IMpath.includes(refcode)) {
            IMreferFlag = true;
        }
    }
    // 精度处理
    let scale;
    if (itemtype === 'number') {
        scale = (getItemValue(attrcode) || {}).scale;
        if (scale === undefined || scale === null || scale == '-1' || scale == -1) {
            scale = props.scale;
        }
        if (scale === undefined || scale === null || scale == '-1' || scale == -1) {
            scale = 0;
        }
        props.scale = Number(scale);
    }

    let itemDom = <Item
        {...props}
        placeholder={''}
        browse={realBrowse}
        maxlength={maxlength}
        isCheckChinese={!!maxlength}
        // key={attrcode}
        setFieldValue={({ value, attrcode, componentValue }) => {
            let filedValue;
            // 处理参照值 
            if (props.itemtype === 'refer') {
                filedValue = { ...value, ...componentValue }
            } else {
                filedValue = value
            }
            // 供应链换算率 建议移除 不应该加载组件内部
            if (itemtype === 'input' && ['vchangerate', 'vqtunitrate'].includes(attrcode)) {
                let reg = /^[0-9\/\.]+$/;
                if (filedValue?.value && !reg.test(filedValue.value)) { //只能输入数字和/
                    return;
                }
            }

            setItemValue(attrcode, filedValue, { inner: true });
        }}
        getFieldValue={({ attrcode }) => {
            let currentValue = getItemValue(attrcode)

            if (['checkbox_switch', 'switch', 'switch_browse'].includes(itemtype) && realBrowse) {
                currentValue.display = currentValue.value ? json["page-form-0007"] : json["page-form-0004"]
            }

            return currentValue;
        }}
        onBeforeEvent={async ({ value, event }) => {
            // 快捷键相关 TODO 编辑前一些快捷键的特殊情况处理
            // let { getHotKeyConfig = () => { } } = config;
            // let { meta, orderOfHotKey, onLastFormEnter } = getHotKeyConfig() || {};
            // let { getStore, getAllFormItem, getSingleForm } = form.getInnerHooks();
            // console.log(form);
            // // 處理states 數據 TODO 後面可以考慮優化下
            // let items = getAllFormItem() || {};
            // let states = {
            //     disabled: {},
            //     visible: {},
            //     form: {},
            // };
            // for (let key in items) {
            //     let item = items[key];
            //     states.disabled[item.attrcode] = !!item.disabled;
            //     states.visible[item.attrcode] = !!item.visible;
            //     states.form[item.attrcode] = item;
            // }
            // let formList = getStore('formList');
            // let itemKeys = [];
            // formList.map(id => itemKeys = itemKeys.concat([], getSingleForm(id) || []));
            // let result = findFocusItem(itemKeys, states.disabled, props);
            // console.log(formList, itemKeys, states.disabled, props, result);
            // if (that.keyDownTab) {
            //     handlerEnter();
            //     that.keyDownTab = false;
            // } else if (that.keyDownTabShift) {
            //     PubSub.publish('autoFocus', { data: result.next });
            //     that.keyDownTabShift = false;
            // } else {
            //     target.fromProgram
            //         ? handlerEnter()
            //         : target.blur();
            //     delete target.fromProgram;
            // }

            if (typeof onBeforeEvent == 'function') {
                return await onBeforeEvent(areaId, attrcode, value, getAllFormValue(), props, event) !== false
            } else {
                return true
            }
        }}
        onAfterEvent={({ value, oldValue, componentValue }) => {
            if (itemtype === 'residtxt') {
                // 主语言没值时，取登录语言的值
                if (!value[attrcode]?.value) {
                    let loginCode = getLangCode(),
                        loginIndex = languageMeta.find(e => e.languageCode === loginCode)?.index || '',
                        loginValue = componentValue[attrcode + loginIndex];
                    setItemValue(attrcode, loginValue)
                }
            } else if (itemtype === 'input' && ['vchangerate', 'vqtunitrate'].includes(attrcode)) {
                let reg = /^([1-9][0-9]*|0)(\.\d{1,})?(\/)(([1-9][0-9]*)(\.\d{1,})?|(\d\.\d{1,}))$/;
                if (value?.value && !reg.test(value.value)) { //只能输入数字和/或.
                    value = { value: '' };
                    let titleTip = json['page-form-0009'];
                    let contentTip = json['page-form-0011'];
                    toast({
                        mark: 'form_onBlur',
                        color: 'danger',
                        title: titleTip,
                        content: ` ${contentTip}"1/2"`,
                    });
                    setItemValue(attrcode, value, { inner: true })
                    return;
                }
            }
            setOldValue(attrcode, oldValue);
            if (typeof onAfterEvent == 'function') {
                onAfterEvent({
                    areaId,
                    attrcode,
                    curMetaItem: props,
                    value: props.itemtype == 'refer' ? { ...value, ...componentValue } : value,
                    oldValue,
                    componentValue,
                    allValue: getAllFormValue(),
                    singleFormCode,
                })
            }
        }}
        onEnter={({ e }) => {
            if (config.onCustomEnter) {
                return;
            }
            // 防止重复
            if (window.triggerOnEnter) {
                return;
            }
            console.log('formitem enter trigger@baseComponent');
            // 只触发一次
            window.triggerOnEnter = true;
            setTimeout(() => {
                let result = fastToNext(attrcode, itemtype);
                window.triggerOnEnter = false;
            }, 20);
        }}
        path={[attrcode]}
    // whichKeyToDisplay={(getItemValue(attrcode) || {}).display ? 'display' : 'value'}
    />
    if (realBrowse && typeof render === 'function') {
        itemDom = render(getItemValue(attrcode), getAllFormValue());
    }
    // if (status === "browse") {
    //     itemDom = <React.Fragment><BrowseBool bool={itemValue.value} type={itemtype}/>{children}</React.Fragment>
    // }
    let attrClassName = processCodeClassName(attrcode);

    let style = {}
    if (itemtype === 'textarea' && !realBrowse) {
        style={
            minWidth: String(width) + '%',
            maxHeight: 'none',
        }
    } else {
        style = { width: String(width) + '%' }
    }

    return (
        <React.Fragment>
            <div
                style={{ width: String(leftWidth) + '%' }}
                className="leftspace form-item"
            />
            <div
                style={style}
                className={`${attrClassName} js-type-${itemtype} form-item`}
                fieldid={getSysFieldid(attrcode)}
                hotkeytag={`${singleFormCode}-${attrcode}`}
            >
                <div className="form-item-label" style={{ color }} title={label}>
                    {(!realBrowse && required) && <span className="u-mast">*</span>}
                    {label}
                    <span className="form-item-colon">{colon}</span>
                </div>
                <Tooltip
                    placement="top"
                    inverse
                    overlay={content}
                    trigger={['hover', 'focus']}
                    id="top"
                    className={`${verify === false && !realBrowse ? 'form-item-show-tip' : 'form-item-hide-tip'}`}
                >
                    <div className={`form-item-control ${verify === false ? 'verify-error' : ''}`}>
                        <div
                            className={`form-component-item-wrapper ${itemtype}-wrapper ${status}`}
                            onKeyDown={e => {
                                // console.log('cell onKeyDow trigger', e);
                                if (e.key === 'Enter') {
                                    if (config.onCustomEnter) {
                                        config.onCustomEnter(areaId, attrcode, itemtype);
                                        return;
                                    }
                                    // 防止重复
                                    if (window.triggerOnEnter) {
                                        return;
                                    }
                                    window.triggerOnEnter = true;
                                    e.stopPropagation();
                                    console.log('formitem enter trigger@itemSpan');
                                    setTimeout(() => {
                                        let result = fastToNext(attrcode, itemtype);
                                        window.triggerOnEnter = false;
                                    }, 20);
                                }

                            }}
                        >
                            {realBrowse ? (hyperlinkflag ? <a style={{ cursor: 'pointer' }} className="form-hyperlink" onClick={() => handleClick(hyperlinkflag, attrcode, status, getStore(['pageid']), singleFormCode, (getItemValue(attrcode) || {}).value)}>{itemDom}</a> : <span className="form-item-pre">{itemDom}</span>) : itemDom}
                            {realBrowse ? (IMreferFlag ? <i className="iconfont icon-renyuan imrefer" onClick={() => handleIMrefer((getItemValue(attrcode) || {}).value, json)} /> : null) : null}
                        </div>
                    </div>
                </Tooltip>

            </div>
        </React.Fragment>
    );
}
// 超链接处理
function handleClick(hyperlinkflag, name, pagestatus, pageId, areaCode, dataId) {
    if (hyperlinkflag && pagestatus === 'browse' && dataId) {
        linkTo(pageId, areaCode, name, dataId);
    }
}

// im处理 
function handleIMrefer(dataId, json) {
    ajax({
        url: '/nccloud/platform/login/openim.do',
        data: { userid: dataId },
        success: res => {
            if (res && res.data) {
                // IM window
                window.top.ImTool && window.top.ImTool.dispatch('switchChatTo', { yht_id: res.data, type: 'chat' });
                // IM 存在 不再点击
                if (window.top.document.getElementById('ykj-frame') && window.top.document.getElementById('ykj-frame').style.display == 'none') {
                    let imBtnForNcc = window.top.document.getElementById('imBtnForNcc');
                    imBtnForNcc && imBtnForNcc.click();
                }
            } else {
                if (res.success && !res.data) {
                    toast({
                        mark: 'userim-wraning',
                        color: 'danger',
                        title: json['page-form-0009'],
                        content: json['page-form-0012'],
                    });
                }
            }
        },
        error: () => {
            console.log('IM');
        },
    });
}

export default Filed;
